.contact__heading {
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.contact__socials-list {
  max-width: 350px;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    margin-right: 0;
    gap: 10px;
  }
}

.contact__segments-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: 0 auto;
  gap: 30px;
}

.contact__segments-item {
  position: relative;
  max-width: 300px;
  padding: 20px;

  background-color: #fff;
  border-radius: 20px;
  box-shadow: $box-shadow;

  @media screen and (min-width: 768px) {
    max-width: 340px;
  }
}
