html {
  box-sizing: border-box;
  width: 100%;
  // @media screen and (max-width: 480px) {
  //   width: 480px;
  // }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

main {
  background-color: $background-color;
}

body {
  margin: 0;
  font-family: Inter;
  background-color: #fff;
}

section {
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  @media screen and (min-width: 768px) {
    padding: 30px 0;
  }
}

section.animate-wrapper {
  padding: 0;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

h2 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: Inter;
  font-weight: 700;
  font-size: 36px;
  line-height: 1.17;
  text-align: center;
  letter-spacing: 0.03em;

  color: $main-text-color;
}

h3 {
  margin-top: 30px;
  margin-bottom: 10px;

  color: $main-text-color;
}

p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;

  letter-spacing: 0.03em;
  color: $main-text-color;
}

.subtitle {
  text-align: center;
  margin-bottom: 30px;
  &--left {
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    &--left {
      text-align: center;
    }
  }
}

.title--left {
  text-align: left;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.container {
  display: block;
  padding: 0 15px;
  margin: 0 auto;
  width: auto;

  @media screen and (min-width: 768px) {
    width: 768px;
  }

  @media screen and (min-width: 1140px) {
    width: 1140px;
  }
}

.logo {
  @include logo;

  &__subtitle {
    font-family: Inter;
    font-size: 24px;
  }
}

.button {
  cursor: pointer;
  &.primery {
    @include button;
    background-color: $accent;
    color: #fff;
  }

  &.secondary {
    @include button;
    background-color: transparent;
    border: 1px solid #030303;
    color: #030303;
  }
}

#root {
  // background-color: $background-color;
}

.half-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    width: 200px;
  }

  @media screen and (max-width: 768px) {
    align-items: center;
  }
}
