.contact__button {
  text-align: center;
  display: block;
  width: auto;
  max-width: 240px;

  margin: 0 auto;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  color: #fff;
  background-color: #2ad027;

  @media screen and (max-width: 290px) {
    font-size: 14px;
  }
}
