.price {
  overflow: hidden;
  padding-bottom: 0;
}

.price__list {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  // gap: 40px;

  @media screen and (min-width: 768px) {
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
  }
}
.price__wrapper {
  display: block;
  width: 350px;
  height: 100%;
  margin: 30px 10px;
}
.price__item {
  padding: 20px 30px;
  width: 230px;
  height: 380px;
  // margin: 0 20px;
  // max-width: 260px;

  background-color: #fff;
  box-shadow: 0px 16px 15px -7px rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  transition: transform 250ms $bezier;

  & p {
    font-size: 18px;
    margin-bottom: 0;
  }

  & h4 {
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & button.button {
    display: block;
    margin: 0 auto;
    letter-spacing: 0;
    font-size: 14px;
    transition: all 250ms $bezier;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media screen and (min-width: 768px) {
    margin: 0;
    width: 300px;
    height: auto;
    &:nth-child(2) {
      z-index: 0;
      transform: scale(1.15);
      &:hover {
        transform: translateY(-15px) scale(1.15);
      }
    }
    &:hover {
      transform: translateY(-15px);
    }
  }
}

.price__value {
  font-size: 32px;
  height: auto;
}

.custom-style-slider {
  // transform: translateX(-40px);
  width: 500px;
  min-width: 380px;
  // margin: 0 auto;
}

.slick-list {
  padding: 0;
}

.price__item.active-slide {
  transform: scale(1.07);
}

.slider-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 400px;
  overflow: hidden;
}

.price__modal {
  position: absolute;
  padding: 30px 50px;
  background-color: #fff;
  box-shadow: $box-shadow;
  border-radius: 30px;
  button {
    display: block;
    margin: 0 auto;
    margin-top: 100px;
    transition: all 200ms $bezier;
  }
  button:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width:768px) {
max-width: 480px;
    width: 90%; 
    h2{
      font-size: 24px;
    }
    button{
      margin-top: 30px;
    }
  }
}

.price__list-of-banks {
  display: flex;
  justify-content: center;
  gap: 70px;

  margin-top: 100px;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    transition: all 150ms $bezier;
    img {
      width: 100%;
      height: auto;
    }
    &:hover {
      transform: scale(1.05);
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: 40px;
    li{
      width: 80px;
      height: 80px;
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 200ms $bezier;
  z-index: 3;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
