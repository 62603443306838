footer {
  padding: 30px 0;
  border-top: 2px solid lightgray;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1140px) {
      & {
        flex-direction: column-reverse;
      }
    }
  }
}

.footer__info {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 300px;
  min-height: 270px;
  text-align: center;

  .terms {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}


.footer__form-wrapper {
  flex: 0 1 700px;
  display: flex;

  background-color: #fff;
  padding: 30px 30px;
  border-radius: 30px;
  box-shadow: $box-shadow;

  .footer__form-text-wrapper {
    flex: 0 0 350px;
  }

  .footer__form {
    margin: 0;
    flex: 0 2 300px;
  }

  @media screen and (max-width: 1140px) {
    & {
      max-width: 400px;
      flex: 0 1 auto;
      flex-direction: column;
    }
    .footer__form-text-wrapper {
      flex: 0 0 auto;
    }
    .footer__form {
      margin: 0 auto;
      flex: 0 0 auto;
    }
  }
}

.logo--footer {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 0;
}

.logo__subtitle {
  margin: 0;
}

.footer__hotfix {
  font-weight: 400;
  text-align: 'center';

  span {
    color: $accent;
  }
}
