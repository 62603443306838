.contact__phone {
  display: flex;
  gap: 10px;
  &-number {
    font-size: 18px;
  }

  &-icon {
    font-size: 24px;
  }
}

.copied-status {
  position: absolute;
  bottom: 6px;
  right: 8px;
  padding: 10px;
  font-size: 18px;
  border-radius: 15px;
  color: #fff;
  background-color: #09d32b84;
}