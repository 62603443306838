.hero {
  background: url('../../images/hero-bg.png');
  background-repeat: no-repeat;

  @media screen and (max-width: 1140px) {
    background-position: -100px 0px;
  }

  @media screen and (max-width: 768px) {
    background: none;
  }
}

.hero .container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 60px;
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
}

.hero__half-wrapper {
  max-width: 650px;
}

h1.hero__title {
  font-size: 60px;
  font-weight: 600;
  line-height: 0.9;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    font-size: 38px;
  }
}

.hero__subtitle {
  text-align: left;
}

.hero__logo-wrapper {
  @include img-wrapper(600px);

  @media screen and (max-width: 768px) {
    @include img-wrapper(300px) // display: none;
;
  }
}

.hero__logo {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hero__button-group {
  display: flex;
  gap: 40px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
}
