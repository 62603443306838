.contact__segments-item {
  .contact__form {
    position: relative;
  }
}

.contact__button-submit {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  position: absolute;
  margin: auto;
  top: 3px;
  right: 3px;
  width: 38px;
  height: 38px;
  padding: 0;

  border: none;
  background-color: $accent;
  color: #fff;
  border-radius: 10px;
  font-size: 24px;
  transition: all 180ms $bezier;

  &[disabled],
  &[disabled]:hover {
    background-color: lightgrey;
  }

  &:hover {
    background-color: #0d94ee;
  }
}

.loader-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0 auto;
}
.loader-2 {
  height: 33px;
  width: 33px;
  transform-origin: 15px 16px;
  animation: loader-2-1 3s linear infinite;
}

@keyframes loader-2-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-2 span {
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
  height: 30px;
  width: 30px;
  clip: rect(16px, 30px, 30px, 0);
  animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

@keyframes loader-2-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-2 span::before {
  content: '';
  display: block;
  position: absolute;
  height: 30px;
  width: 30px;
  border: 3px solid transparent;
  border-top: 3px solid $accent;
  border-radius: 50%;
  animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

@keyframes loader-2-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-2 span::after {
  content: '';
  display: block;
  position: absolute;
  height: 30px;
  width: 30px;
  border: 3px solid rgba(49, 100, 244, 0.5);
  border-radius: 50%;
}
